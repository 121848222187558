<template>
  <b-container class="p-0" fuild style="max-width: 2560px">
    <b-row>
      <b-col :md="admin ? '5' : '12'" class="row-eq-height">
        <identity-card
          v-model="identity"
          :change-password="!isLdap"
          :account_type="'isp'"
          :visible_for_permissions='[{actions: ["PATCH"], path: "/manager-loggedin"}]'
        >
          <div slot="header">
            <h5 class="title">
              <i class="mr-2"><svgicon class="icon" icon="item-admin" /></i>
              <template>
                {{ t("authentication") }}
              </template>
            </h5>
          </div>
        </identity-card>
      </b-col>
      <b-col md="7" v-if="admin" class="entranceFromRight row-eq-height">
        <edit-profile-form v-model="profile" :editable="!isLdap" :visible_for_permissions='[{actions: ["PATCH"], path: "/manager-loggedin"}]' :isIspAdmin='isIspAdmin'>
          <div slot="header">
            <h5 class="title">
              <i class="now-ui-icons business_badge mr-2" />{{ t("profile") }}
            </h5>
          </div>
        </edit-profile-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import EditProfileForm from "../Managers/EditProfileForm";
import IdentityCard from "../Managers/IdentityCard";
import RolesForm from "./RolesForm";
import isIspAccount from '@/util/util';
import { mapGetters } from "vuex";

export default {
  name: "administrator-profile-edit",
  components: {
    EditProfileForm,
    IdentityCard,
    RolesForm,
  },
  props: ["id"],
  data() {
    return {
      admin: null,
      saved_admin: null
    };
  },
  mounted() {
    this.refresh(0);
  },
  methods: {
    refresh(wait) {
      this.$store.dispatch("generalManagement/administrators/getRoles", wait);
      this.$store.dispatch("providers/getActiveProviders");
      if (this.id) {
        let _vm = this;
        this.$store
          .dispatch("generalManagement/administrators/getAdministratorProfile")
          .then((res) => (_vm.admin = res))
          .catch((err) => this.$router.replace("/"));
      }
    },
    updateAdminProfile(data_to_retry){
      this.$store.dispatch("generalManagement/administrators/updateAdministratorProfile", { id: this.realid, data: data_to_retry })
      .then((response) => {
        this.$store.commit("setSuccess", this.t("save_success"))
        this.admin = _.merge(this.admin, data_to_retry)
      })
      .catch((error) => {
        if (error.response && error.response.data){
          let data = error.response.data
          if ( data.type.endsWith('NEEDS_AUTHORIZATION')){
            // open re-authentication dialog, result will be provided by change in privileges
            this.$store.commit('auth/toggleReauthenticationModal', {
                      toggle: true, 
                      privileges_requested: data.title.split(','),
                      retry_data: data_to_retry
            });
          }
          else if (data.title.includes("identity.email")) {
            this.$store.commit("setError", this.t("errors_email"));
          } else if (data.title.includes("identity.phone_number")) {
            this.$store.commit("setError", this.t("errors_phone"));
          } else {
            this.$store.commit("setError", error);
          }
        }
      });
    }
  },
  watch: {
    privileges: function(newValue, oldValue){
      if (newValue && newValue.length){
        this.updateAdminProfile(this.retry_data)
      }
    },
    admin(newValue, oldValue){
        this.saved_admin = newValue
    }
  },
  computed: {
    ...mapGetters('auth', ['privileges', 'retry_data']),
    isLdap() {
      return (
        this.identity &&
        this.identity.identity_provider_type === "IdentityProviderLDAP"
      );
    },
    realid() {
      return this.id;
    },
    profile: {
      get() {
        let ret = null;
        ret = {
          created_at: this.admin.identity.created_at,
          user_id: this.admin.identity.identity_instances.user_id,
          email: this.admin.identity.email,
          name: this.admin.identity.name,
          phone_number: this.admin.identity.phone_number,
          updated_at: this.admin.identity.updated_at,
          language_id: this.admin.manager_metadata.language_id,
          time_zone: this.admin.manager_metadata.time_zone,
        };
        return ret;
      },
      set(newValue) {
        let data = {
          identity: {},
          manager_metadata: {
            language_id: newValue.language_id,
            time_zone: newValue.time_zone,
          },
        };

        if (!!newValue.name)
            data.identity.name = newValue.name
        else if (!!this.saved_admin.identity.name)
            data.identity.name = ''

        if (!!newValue.phone_number){
            if (newValue.phone_number !== this.saved_admin.identity.phone_number)
                data.identity.phone_number = newValue.phone_number
        }
        else if (!!this.saved_admin.identity.phone_number)
            data.identity.phone_number = null

        if (!!newValue.email){
            if (newValue.email !== this.saved_admin.identity.email)
                data.identity.email = newValue.email
        }
        else if (!!this.saved_admin.identity.email)
            data.identity.email = null

        this.updateAdminProfile(data)
      },
    },
    identity: {
      get() {
        let ret = null;
        if (this.admin) {
            ret = {
            blocked: this.admin.identity.blocked,
            identity_instance_type: this.admin.identity.identity_instances
                .identity_instance_type,
            identity_provider_type: this.admin.identity.identity_instances
                .identity_provider_type,
            user_id: this.admin.identity.identity_instances.user_id,
            last_ip: this.admin.identity.last_ip,
            last_login: this.admin.identity.last_login,
            last_password_reset: this.admin.identity.last_password_reset,
            IdentityInstanceDatabase: {
                password: "",
            }
            };
        }
        return ret;
      },
      set(newValue) {
        let data = {
          identity: {
            blocked: newValue.blocked,
          }
        };
        if (!!newValue[newValue.identity_instance_type].password) {
          let password = newValue[newValue.identity_instance_type].password;
          data.identity.identity_instances = {
            user_id: newValue.user_id,
            identity_instance_type: newValue.identity_instance_type,
            identity_provider_type: newValue.identity_provider_type,
            password: password,
          };
        }

        this.updateAdminProfile(data)
      },
    },
    isIspAdmin() {
      return isIspAccount(this.admin.account_id);
    }    
  },
};
</script>
<style>
</style>
